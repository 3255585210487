import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Slider,
  Grid,
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';

const BACKEND_URL = 'localhost:8000';

const DIET_OPTIONS = [
  { value: '', label: 'None' },
  { value: 'KETO', label: 'Keto' },
  { value: 'VEGAN', label: 'Vegan' },
  { value: 'VEGETARIAN', label: 'Vegetarian' },
  { value: 'GLUTEN_FREE', label: 'Gluten Free' },
  { value: 'DAIRY_FREE', label: 'Dairy Free' },
  { value: 'PALEO', label: 'Paleo' },
];

const RecipeGenerator = () => {
  const [formData, setFormData] = useState({
    ingredients: '',
    diet: '',
    strict: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [recipe, setRecipe] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleStrictChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      strict: newValue
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.ingredients.trim()) {
      setError('Please enter some ingredients');
      return;
    }

    setLoading(true);
    setError('');
    setRecipe('');

    try {
      const response = await fetch(`http://${BACKEND_URL}/generateRecipe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate recipe');
      }

      const data = await response.json();
      setRecipe(data.recipe);
    } catch (err) {
      setError(err.message);
      console.error('Error generating recipe:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderRecipe = () => {
    if (!recipe) return null;

    // Split the recipe into sections based on markdown headers
    const sections = recipe.split('###').filter(Boolean);

    return sections.map((section, index) => (
      <Paper key={index} elevation={2} sx={{ p: 2, mt: 2 }}>
        <Typography
          variant="body1"
          component="div"
          sx={{
            whiteSpace: 'pre-line',
            '& h4': { mt: 2, mb: 1 },
            '& ul': { pl: 3 },
          }}
        >
          {section}
        </Typography>
      </Paper>
    ));
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <RestaurantIcon fontSize="large" />
        Recipe Generator
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Ingredients"
                name="ingredients"
                value={formData.ingredients}
                onChange={handleChange}
                placeholder="Enter your ingredients (e.g., chicken, broccoli, rice)"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Dietary Preference</InputLabel>
                <Select
                  name="diet"
                  value={formData.diet}
                  onChange={handleChange}
                  label="Dietary Preference"
                >
                  {DIET_OPTIONS.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography gutterBottom>
                Ingredient Strictness: {formData.strict}
              </Typography>
              <Slider
                value={formData.strict}
                onChange={handleStrictChange}
                step={1}
                marks
                min={0}
                max={2}
                valueLabelDisplay="auto"
                sx={{ mt: 2 }}
              />
              <Typography variant="caption" color="text.secondary">
                0: May add ingredients | 1: Only listed ingredients | 2: Suggest additional ingredients
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? 'Generating Recipe...' : 'Generate Recipe'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {renderRecipe()}
    </Box>
  );
};

export default RecipeGenerator;
