import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import imageCompression from 'browser-image-compression';

const BACKEND_URL = 'localhost:8000';

const AnalyzeFood = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [analysis, setAnalysis] = useState(null);

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 0.3,          // Maximum size in MB (300KB)
      maxWidthOrHeight: 1024,   // Maximum width/height
      useWebWorker: true,
      fileType: 'image/jpeg'    // Convert to JPEG for better compression
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('Original file size:', imageFile.size / 1024 / 1024, 'MB');
      console.log('Compressed file size:', compressedFile.size / 1024 / 1024, 'MB');
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw new Error('Failed to compress image');
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        setLoading(true);
        const compressedFile = await compressImage(selectedFile);
        setFile(compressedFile);
        setPreview(URL.createObjectURL(compressedFile));
      } catch (err) {
        setError('Failed to process image: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError('Please select an image to analyze');
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('file', file, 'food_image.jpg'); // Fixed filename

    try {
      const response = await fetch(`http://${BACKEND_URL}/analyzeFoodPlate`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ detail: 'Failed to analyze food plate' }));
        throw new Error(errorData.detail || 'Failed to analyze food plate');
      }

      const data = await response.json();
      console.log('Received data:', data);
      setAnalysis(data.result);
    } catch (err) {
      setError(err.message);
      console.error('Error analyzing food:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderNutritionInfo = () => {
    if (!analysis) return null;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Foods Identified</Typography>
            <List>
              {analysis.foods_identified?.map((food, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={food.name}
                      secondary={
                        <Typography variant="body2" color="text.secondary">
                          Portion: {food.portion_size}<br />
                          Calories: {food.calories} | Protein: {food.protein} | Carbs: {food.carbs} | Fats: {food.fats}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < (analysis.foods_identified?.length || 0) - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Total Nutrition</Typography>
            {analysis.total_nutrition && (
              <Typography variant="body1">
                Calories: {analysis.total_nutrition.total_calories}<br />
                Protein: {analysis.total_nutrition.total_protein}<br />
                Carbs: {analysis.total_nutrition.total_carbs}<br />
                Fats: {analysis.total_nutrition.total_fats}<br />
                Fiber: {analysis.total_nutrition.fiber}
              </Typography>
            )}

            <Typography variant="h6" sx={{ mt: 2 }}>Vitamins & Minerals</Typography>
            <Box sx={{ mt: 1 }}>
              {analysis.vitamins_minerals?.map((vitamin, index) => (
                <Chip key={index} label={vitamin} sx={{ m: 0.5 }} />
              ))}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Meal Analysis</Typography>
            {analysis.meal_analysis && (
              <Typography variant="body1">
                Type: {analysis.meal_analysis.meal_type}<br />
                Balance Score: {analysis.meal_analysis.balance_score}/10<br />
                Protein Ratio: {analysis.meal_analysis.protein_ratio}<br />
                Carb Ratio: {analysis.meal_analysis.carb_ratio}<br />
                Fat Ratio: {analysis.meal_analysis.fat_ratio}
              </Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Health Insights</Typography>
            {analysis.health_insights && (
              <>
                <Typography variant="body1" gutterBottom>
                  {analysis.health_insights.meal_balance}
                </Typography>

                <Typography variant="subtitle1" gutterBottom>Positive Aspects:</Typography>
                <List dense>
                  {analysis.health_insights.positive_aspects?.map((aspect, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={aspect} />
                    </ListItem>
                  ))}
                </List>

                <Typography variant="subtitle1" gutterBottom>Areas for Improvement:</Typography>
                <List dense>
                  {analysis.health_insights.improvement_areas?.map((area, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={area} />
                    </ListItem>
                  ))}
                </List>

                <Typography variant="subtitle1" gutterBottom>Suggestions:</Typography>
                <List dense>
                  {analysis.health_insights.suggestions?.map((suggestion, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={suggestion} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Analyze Food Plate
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="food-image-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="food-image-upload">
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Food Image
          </Button>
        </label>

        {preview && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <img
              src={preview}
              alt="Food preview"
              style={{ maxWidth: '100%', maxHeight: '300px' }}
            />
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!file || loading}
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Analyze'}
        </Button>
      </Paper>

      {renderNutritionInfo()}
    </Box>
  );
};

export default AnalyzeFood;
