import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  ListItemIcon,
} from '@mui/material';
import {
  Mic,
  MicOff,
  PhotoCamera,
  Send,
  Edit,
  Delete,
  Save,
  FitnessCenter,
  DirectionsRun,
  Check,
  Close,
  ErrorOutline,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

// Styled components
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const VoiceButton = styled(Button)(({ theme, islistening }) => ({
  height: islistening ? '120px' : '48px',
  transition: 'all 0.3s ease',
  background: islistening ? theme.palette.error.main : theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    background: islistening ? theme.palette.error.dark : theme.palette.primary.dark,
  },
}));

const TranscriptPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
}));

const WorkoutLogger = () => {
  // Add workout type state
  const [workoutType, setWorkoutType] = useState('gym'); // 'gym' or 'cardio'
  
  // State for input methods
  const [isRecording, setIsRecording] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // State for workout data
  const [workoutData, setWorkoutData] = useState(null);
  const [workoutHistory, setWorkoutHistory] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [showTranscriptDialog, setShowTranscriptDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedTranscript, setEditedTranscript] = useState('');
  const [processingWorkout, setProcessingWorkout] = useState(false);
  const [workoutResult, setWorkoutResult] = useState(null);
  const [showManualEntry, setShowManualEntry] = useState(false);
  const [editingResult, setEditingResult] = useState(false);
  const [editedResult, setEditedResult] = useState(null);
  const [success, setSuccess] = useState('');

  // Add new state variables for workout details
  const [workoutName, setWorkoutName] = useState('');
  const [workoutDate, setWorkoutDate] = useState(new Date().toISOString().split('T')[0]);  // YYYY-MM-DD format

  // Add new state variables at the top with other states
  const [distanceUnit, setDistanceUnit] = useState('miles'); // 'miles' or 'km'

  // Add theme and media query
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Add conversion functions
  const convertDistance = (value, fromUnit, toUnit) => {
    if (!value) return 0;
    const val = parseFloat(value);
    if (fromUnit === toUnit) return val;
    return toUnit === 'km' ? val * 1.60934 : val / 1.60934;
  };

  const convertPace = (pace, fromUnit, toUnit) => {
    if (!pace) return '';
    const [minutes, seconds = '00'] = pace.split(':');
    const totalMinutes = parseInt(minutes) + parseInt(seconds) / 60;
    
    if (fromUnit === toUnit) return pace;
    
    let newPaceMinutes;
    if (toUnit === 'km') {
      newPaceMinutes = totalMinutes / 1.60934;
    } else {
      newPaceMinutes = totalMinutes * 1.60934;
    }
    
    const newMinutes = Math.floor(newPaceMinutes);
    const newSeconds = Math.round((newPaceMinutes - newMinutes) * 60);
    return `${newMinutes}:${newSeconds.toString().padStart(2, '0')}`;
  };

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let currentTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          currentTranscript += event.results[i][0].transcript;
        }
        setTranscript(currentTranscript);
        setEditedTranscript(currentTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setError('Speech recognition error: ' + event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        if (transcript) {
          setShowTranscriptDialog(true);
        }
      };

      setRecognition(recognition);
    } else {
      setError('Speech recognition is not supported in this browser');
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  const startRecording = async () => {
    setError('');
    setTranscript('');
    setEditedTranscript('');
    
    if (recognition) {
      try {
        await recognition.start();
        setIsListening(true);
      } catch (err) {
        console.error('Error starting recognition:', err);
        setError('Could not start speech recognition');
      }
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      setEditDialogOpen(true);
      setEditedTranscript(transcript);
    }
  };

  const handleWorkoutTypeChange = (event, newType) => {
    if (newType !== null) {
      setWorkoutType(newType);
      // Reset states when changing type
      setInputText('');
      setTranscript('');
      setEditedTranscript('');
      setWorkoutData(null);
      setShowPreview(false);
    }
  };

  // Modify handleTextSubmit to use different endpoints based on type
  const handleTextSubmit = async () => {
    if (!inputText.trim()) return;

    setLoading(true);
    setError('');

    try {
      const endpoint = workoutType === 'gym' 
        ? 'process-gym-workout'
        : 'process-cardio-workout';

      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
      });

      if (!response.ok) {
        throw new Error(`Failed to process ${workoutType} workout`);
      }

      const data = await response.json();
      setWorkoutData(data);
      setShowPreview(true);
      setInputText('');
    } catch (err) {
      console.error('Error processing text input:', err);
      setError('Failed to process text input');
    } finally {
      setLoading(false);
    }
  };

  // Handle image input
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    setError('');

    try {
      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const formData = new FormData();
      formData.append('image', file);
      formData.append('workout_type', workoutType);

      const response = await fetch(`${BACKEND_URL}/process-image-workout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to process ${workoutType} workout image`);
      }

      const data = await response.json();
      setWorkoutData(data);
      setShowPreview(true);
      setSelectedImage(URL.createObjectURL(file));
    } catch (err) {
      console.error('Error processing image:', err);
      setError(`Failed to process ${workoutType} workout image: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Modify handleSaveWorkout to include name and date
  const handleSaveWorkout = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${BACKEND_URL}/save-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(workoutData)
      });

      const data = await response.json();

      if (!response.ok) {
        // Handle validation errors
        if (response.status === 400 && data.errors) {
          setError(
            <div>
              <Typography color="error" gutterBottom>
                {data.message}
              </Typography>
              <List>
                {data.errors.map((error, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ErrorOutline color="error" />
                    </ListItemIcon>
                    <ListItemText primary={error} />
                  </ListItem>
                ))}
              </List>
            </div>
          );
        } else {
          throw new Error(data.message || 'Failed to save workout');
        }
      } else {
        // Success case
        setSuccess('Workout saved successfully!');
        // Reset form
        setWorkoutData(null);
        setSelectedImage(null);
        setInputText('');
        setTranscript('');
        
        // Clear success message after 3 seconds
        setTimeout(() => {
          setSuccess(null);
        }, 3000);
      }
    } catch (err) {
      console.error('Error saving workout:', err);
      setError(
        <Typography color="error">
          {err.message || 'Failed to save workout. Please try again.'}
        </Typography>
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitTranscript = async () => {
    try {
      setProcessingWorkout(true);
      setEditDialogOpen(false);

      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const endpoint = workoutType === 'gym' 
        ? 'process-gym-workout'
        : 'process-cardio-workout';

      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ text: editedTranscript }),
      });

      if (!response.ok) {
        throw new Error(`Failed to process ${workoutType} workout`);
      }

      const data = await response.json();
      console.log('Received workout data:', data);
      setWorkoutResult(data);
      setEditedResult(data);
      setEditingResult(false);

    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setProcessingWorkout(false);
    }
  };

  const handleSaveEditedWorkout = async () => {
    try {
      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${BACKEND_URL}/save-workout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(editedResult),
      });

      if (!response.ok) {
        throw new Error('Failed to save workout');
      }

      setEditingResult(false);
      // Show success message
      setSuccess('Workout saved successfully!');
    } catch (error) {
      setError('Failed to save workout');
    }
  };

  // Modify renderWorkoutPreview to include name and date fields
  const renderWorkoutPreview = () => {
    if (!workoutData) return null;

    return (
      <Card sx={{ 
        mt: 2,
        p: 3,
        boxShadow: 3,
        borderRadius: 2
      }}>
        <CardContent sx={{ p: 0 }}>
          {/* Header section */}
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'stretch' : 'center',
            gap: 2,
            mb: 4
          }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: isMobile ? 2 : 0,
                flexShrink: 0,
                minWidth: isMobile ? 'auto' : '200px',
                fontWeight: 500
              }}
            >
              {workoutType === 'gym' ? 'Gym Workout Preview' : 'Cardio Workout Preview'}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              width: '100%',
              alignItems: 'center'
            }}>
              <TextField
                fullWidth
                label="Workout Name"
                value={workoutName}
                onChange={(e) => setWorkoutName(e.target.value)}
                size={isMobile ? "medium" : "small"}
                sx={{ 
                  minWidth: isMobile ? '100%' : '200px',
                  maxWidth: isMobile ? '100%' : '300px',
                  '& .MuiInputLabel-root': {
                    fontSize: isMobile ? '1rem' : '0.875rem'
                  },
                  '& .MuiInputBase-input': {
                    fontSize: isMobile ? '1rem' : '0.875rem',
                    padding: isMobile ? '14px 12px' : '10px'
                  }
                }}
              />
              <TextField
                type="date"
                label="Date"
                value={workoutDate}
                onChange={(e) => setWorkoutDate(e.target.value)}
                size={isMobile ? "medium" : "small"}
                InputLabelProps={{ shrink: true }}
                sx={{ 
                  minWidth: isMobile ? '100%' : '150px',
                  maxWidth: isMobile ? '100%' : '200px',
                  '& .MuiInputLabel-root': {
                    fontSize: isMobile ? '1rem' : '0.875rem'
                  },
                  '& .MuiInputBase-input': {
                    fontSize: isMobile ? '1rem' : '0.875rem',
                    padding: isMobile ? '14px 12px' : '10px'
                  }
                }}
              />
            </Box>
          </Box>

          {workoutType === 'cardio' && (
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={isMobile ? 2 : 3}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 1,
                    mb: isMobile ? 2 : 0,
                    alignItems: 'flex-start'
                  }}>
                    <TextField
                      fullWidth
                      label={`Distance (${distanceUnit})`}
                      type="number"
                      value={workoutData.distance || ''}
                      onChange={(e) => {
                        const newDistance = parseFloat(e.target.value) || 0;
                        setWorkoutData({
                          ...workoutData,
                          distance: newDistance,
                          pace: workoutData.duration ? 
                            calculatePace(newDistance, workoutData.duration) : 
                            workoutData.pace
                        });
                      }}
                      size={isMobile ? "medium" : "small"}
                      sx={{ 
                        flex: 1,
                        '& .MuiInputBase-input': {
                          fontSize: isMobile ? '1rem' : '0.875rem'
                        }
                      }}
                    />
                    <ToggleButtonGroup
                      value={distanceUnit}
                      exclusive
                      size={isMobile ? "medium" : "small"}
                      onChange={(e, newUnit) => {
                        if (newUnit !== null) {
                          setDistanceUnit(newUnit);
                        }
                      }}
                      sx={{ 
                        width: isMobile ? '100%' : 'auto',
                        '& .MuiToggleButton-root': {
                          flex: isMobile ? 1 : 'inherit',
                          padding: isMobile ? '10px 16px' : '6px 12px',
                          fontSize: isMobile ? '1rem' : '0.875rem'
                        }
                      }}
                    >
                      <ToggleButton value="miles">mi</ToggleButton>
                      <ToggleButton value="km">km</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Duration"
                    value={workoutData.duration || ''}
                    onChange={(e) => {
                      const newDuration = e.target.value;
                      setWorkoutData({
                        ...workoutData,
                        duration: newDuration,
                        pace: workoutData.distance ? 
                          calculatePace(workoutData.distance, newDuration) : 
                          workoutData.pace
                      });
                    }}
                    placeholder="HH:MM:SS"
                    size={isMobile ? "medium" : "small"}
                    sx={{ 
                      mb: isMobile ? 2 : 0,
                      '& .MuiInputBase-input': {
                        fontSize: isMobile ? '1rem' : '0.875rem'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={`Pace (per ${distanceUnit})`}
                    value={workoutData.pace || ''}
                    onChange={(e) => setWorkoutData({
                      ...workoutData,
                      pace: e.target.value
                    })}
                    placeholder="MM:SS"
                    size={isMobile ? "medium" : "small"}
                    sx={{ 
                      mb: isMobile ? 2 : 0,
                      '& .MuiInputBase-input': {
                        fontSize: isMobile ? '1rem' : '0.875rem'
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" color="text.secondary">
                          /{distanceUnit === 'miles' ? 'mi' : 'km'}
                        </Typography>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Save Button */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end',
            mt: 3 
          }}>
            <Button
              variant="contained"
              onClick={handleSaveWorkout}
              startIcon={<Save />}
              sx={{ 
                minWidth: 120,
                fontSize: isMobile ? '1rem' : '0.875rem'
              }}
            >
              Save Workout
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const renderWorkoutResult = (result) => {
    if (!result) return null;

    if (workoutType === 'cardio') {
      return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6">Workout Summary</Typography>
            <Box>
              <IconButton onClick={() => {
                setEditingResult(!editingResult);
                setEditedResult(editingResult ? result : { ...result });
              }}>
                {editingResult ? <Check /> : <Edit />}
              </IconButton>
              {editingResult && (
                <IconButton onClick={() => setEditingResult(false)}>
                  <Close />
                </IconButton>
              )}
            </Box>
          </Box>
          {editingResult ? (
            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Type"
                value={editedResult.type || 'Run'}
                onChange={(e) => setEditedResult({...editedResult, type: e.target.value})}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Distance"
                value={editedResult.distance || ''}
                onChange={(e) => setEditedResult({...editedResult, distance: e.target.value})}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Duration"
                value={editedResult.duration || ''}
                onChange={(e) => setEditedResult({...editedResult, duration: e.target.value})}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Pace"
                value={editedResult.pace || ''}
                onChange={(e) => setEditedResult({...editedResult, pace: e.target.value})}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Calories"
                value={editedResult.calories || ''}
                onChange={(e) => setEditedResult({...editedResult, calories: e.target.value})}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Notes"
                value={editedResult.notes || ''}
                onChange={(e) => setEditedResult({...editedResult, notes: e.target.value})}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                onClick={handleSaveEditedWorkout}
                startIcon={<Save />}
              >
                Save Workout
              </Button>
            </Box>
          ) : (
            <List>
              <ListItem>
                <ListItemText primary={`Type: ${result.type || 'Run'}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Distance: ${result.distance} ${result.unit}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Duration: ${result.duration}`} />
              </ListItem>
              <ListItem>
                <ListItemText primary={`Pace: ${result.pace}`} />
              </ListItem>
              {result.calories && (
                <ListItem>
                  <ListItemText primary={`Calories: ${result.calories}`} />
                </ListItem>
              )}
              {result.notes && (
                <ListItem>
                  <ListItemText primary={`Notes: ${result.notes}`} />
                </ListItem>
              )}
            </List>
          )}
          {!editingResult && (
            <Button
              variant="contained"
              onClick={handleSaveEditedWorkout}
              startIcon={<Save />}
              sx={{ mt: 2 }}
            >
              Save Workout
            </Button>
          )}
        </Paper>
      );
    } else {
      // Similar edit functionality for gym workouts
      return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6">Workout Summary</Typography>
            <Box>
              <IconButton onClick={() => {
                setEditingResult(!editingResult);
                setEditedResult(editingResult ? result : { ...result });
              }}>
                {editingResult ? <Check /> : <Edit />}
              </IconButton>
              {editingResult && (
                <IconButton onClick={() => setEditingResult(false)}>
                  <Close />
                </IconButton>
              )}
            </Box>
          </Box>
          {editingResult ? (
            <Box>
              {editedResult.exercises?.map((exercise, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label="Exercise Name"
                    value={exercise.name}
                    onChange={(e) => {
                      const newExercises = [...editedResult.exercises];
                      newExercises[index] = {...exercise, name: e.target.value};
                      setEditedResult({...editedResult, exercises: newExercises});
                    }}
                    sx={{ mb: 2 }}
                  />
                  {exercise.sets?.map((set, setIndex) => (
                    <Box key={setIndex} sx={{ display: 'flex', gap: 2, mb: 1 }}>
                      {exercise.name === 'Jump Rope' ? (
                        <TextField
                          label="Duration (minutes)"
                          type="number"
                          value={set.duration ? Math.floor(set.duration / 60) : ''}
                          onChange={(e) => {
                            const newExercises = [...editedResult.exercises];
                            newExercises[index].sets[setIndex] = {
                              ...set,
                              duration: parseInt(e.target.value) * 60,
                              reps: null
                            };
                            setEditedResult({...editedResult, exercises: newExercises});
                          }}
                        />
                      ) : (
                        <TextField
                          label="Reps"
                          type="number"
                          value={set.reps || ''}
                          onChange={(e) => {
                            const newExercises = [...editedResult.exercises];
                            newExercises[index].sets[setIndex] = {
                              ...set,
                              reps: parseInt(e.target.value),
                              duration: null
                            };
                            setEditedResult({...editedResult, exercises: newExercises});
                          }}
                        />
                      )}
                      {exercise.name !== 'Jump Rope' && (
                        <>
                          <TextField
                            label="Weight"
                            type="number"
                            value={set.weight || ''}
                            onChange={(e) => {
                              const newExercises = [...editedResult.exercises];
                              newExercises[index].sets[setIndex] = {
                                ...set,
                                weight: parseInt(e.target.value)
                              };
                              setEditedResult({...editedResult, exercises: newExercises});
                            }}
                          />
                          <TextField
                            label="Unit"
                            value={set.unit || 'lbs'}
                            onChange={(e) => {
                              const newExercises = [...editedResult.exercises];
                              newExercises[index].sets[setIndex] = {
                                ...set,
                                unit: e.target.value
                              };
                              setEditedResult({...editedResult, exercises: newExercises});
                            }}
                          />
                        </>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
              <Button
                variant="contained"
                onClick={handleSaveEditedWorkout}
                startIcon={<Save />}
              >
                Save Workout
              </Button>
            </Box>
          ) : (
            <>
              {result.exercises?.map((exercise, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">{exercise.name}</Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Set</TableCell>
                          <TableCell>Reps/Duration</TableCell>
                          <TableCell>Weight</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {exercise.sets?.map((set, setIndex) => (
                          <TableRow key={setIndex}>
                            <TableCell>{set.setNumber}</TableCell>
                            <TableCell>
                              {set.duration ? 
                                `${Math.floor(set.duration / 60)} minute${set.duration / 60 !== 1 ? 's' : ''}` : 
                                `${set.reps} reps`}
                            </TableCell>
                            <TableCell>
                              {set.weight ? `${set.weight} ${set.unit}` : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))}
              <Button
                variant="contained"
                onClick={handleSaveEditedWorkout}
                startIcon={<Save />}
                sx={{ mt: 2 }}
              >
                Save Workout
              </Button>
            </>
          )}
        </Paper>
      );
    }
  };

  // Add helper function for pace calculation
  const calculatePace = (distance, duration) => {
    if (!distance || !duration) return '';
    
    // Parse duration (HH:MM:SS)
    const [hours = '0', minutes = '0', seconds = '0'] = duration.split(':');
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
    
    // Calculate pace in minutes per unit
    const paceMinutes = totalMinutes / distance;
    
    // Convert to MM:SS format
    const paceWholeMinutes = Math.floor(paceMinutes);
    const paceSeconds = Math.round((paceMinutes - paceWholeMinutes) * 60);
    
    return `${paceWholeMinutes}:${paceSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Container maxWidth="md" sx={{ pb: isMobile ? 7 : 2 }}>
      {/* Success Message */}
      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mb: 2, 
            position: 'fixed', 
            top: 20, 
            left: '50%', 
            transform: 'translateX(-50%)',
            zIndex: 1500,
            minWidth: 300,
            boxShadow: 3
          }}
        >
          {success}
        </Alert>
      )}

      {/* Workout Type Selector - Mobile */}
      {isMobile && (
        <BottomNavigation
          value={workoutType}
          onChange={(_, newValue) => handleWorkoutTypeChange(null, newValue)}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            borderTop: 1,
            borderColor: 'divider',
            bgcolor: theme.palette.background.paper,
            '& .Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiBottomNavigationAction-label': {
                fontSize: '0.875rem',
                fontWeight: 'bold',
              },
              '& .MuiSvgIcon-root': {
                transform: 'scale(1.2)',
              },
            },
          }}
        >
          <BottomNavigationAction 
            label="Gym" 
            value="gym" 
            icon={<FitnessCenter />}
            sx={{
              '&.Mui-selected': {
                borderTop: 3,
                borderColor: 'primary.main',
                paddingTop: '6px',
              },
            }}
          />
          <BottomNavigationAction 
            label="Cardio" 
            value="cardio" 
            icon={<DirectionsRun />}
            sx={{
              '&.Mui-selected': {
                borderTop: 3,
                borderColor: 'primary.main',
                paddingTop: '6px',
              },
            }}
          />
        </BottomNavigation>
      )}

      {/* Workout Type Selector - Desktop */}
      {!isMobile && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ 
            color: 'text.secondary',
            fontSize: '1.25rem',
            fontWeight: 500
          }}>
            Current Mode: {workoutType === 'gym' ? 'Gym Workout' : 'Cardio Workout'}
          </Typography>
          <ToggleButtonGroup
            value={workoutType}
            exclusive
            onChange={handleWorkoutTypeChange}
            aria-label="workout type"
            sx={{ 
              width: '100%',
              '& .MuiToggleButton-root': {
                border: 2,
                py: 2,
                px: 4,
                '&.Mui-selected': {
                  bgcolor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                  '& .MuiSvgIcon-root': {
                    transform: 'scale(1.2)',
                  },
                },
                '&:hover': {
                  bgcolor: 'action.hover',
                },
              },
            }}
          >
            <ToggleButton 
              value="gym" 
              aria-label="gym workout" 
              sx={{ 
                width: '50%',
                display: 'flex',
                gap: 2,
                fontSize: '1.1rem',
                fontWeight: workoutType === 'gym' ? 'bold' : 'normal',
              }}
            >
              <FitnessCenter /> Gym Workout
            </ToggleButton>
            <ToggleButton 
              value="cardio" 
              aria-label="cardio workout" 
              sx={{ 
                width: '50%',
                display: 'flex',
                gap: 2,
                fontSize: '1.1rem',
                fontWeight: workoutType === 'cardio' ? 'bold' : 'normal',
              }}
            >
              <DirectionsRun /> Cardio Workout
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ 
        mb: isMobile ? 8 : 2,
        '& .MuiTextField-root': {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'primary.main',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main',
            },
          },
        },
      }}>
        {error && (
          <Box sx={{ mt: 2, mb: 2 }}>
            {error}
          </Box>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
            {success}
          </Alert>
        )}

        {/* Input Methods Grid */}
        <Grid container spacing={2} sx={{ mb: 3 }}>
          {/* Voice Input Card */}
          <Grid item xs={12}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 2,
                background: isListening ? theme.palette.error.main : theme.palette.background.paper,
                transition: 'all 0.3s ease'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" color={isListening ? 'white' : 'text.primary'}>
                  Voice Input
                </Typography>
                <VoiceButton
                  variant="contained"
                  onClick={isListening ? stopRecording : startRecording}
                  islistening={isListening ? 1 : 0}
                  startIcon={isListening ? <MicOff /> : <Mic />}
                >
                  {isListening ? 'Stop Recording' : 'Start Recording'}
                </VoiceButton>
              </Box>
              {isListening && (
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    minHeight: '50px' 
                  }}
                >
                  {transcript || 'Listening...'}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Manual Input Card */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Manual Input
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder={
                  workoutType === 'gym'
                    ? "e.g., '3 sets of squats at 225 lbs'"
                    : "e.g., 'Ran 3 miles in 24 minutes'"
                }
                sx={{ mb: 2 }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleTextSubmit}
                disabled={!inputText.trim()}
                startIcon={<Send />}
              >
                Process Workout
              </Button>
            </Paper>
          </Grid>

          {/* Image Upload Card */}
          <Grid item xs={12} md={6}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 2, 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: selectedImage ? `url(${selectedImage})` : theme.palette.background.paper,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: selectedImage ? 'rgba(0,0,0,0.6)' : 'transparent',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2
                }}
              >
                <Typography variant="h6" gutterBottom color={selectedImage ? 'white' : 'text.primary'}>
                  Upload Screenshot
                </Typography>
                <input
                  id="image-upload"
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <Button
                  variant="contained"
                  component="label"
                  htmlFor="image-upload"
                  startIcon={<PhotoCamera />}
                  sx={{ mt: 2 }}
                >
                  {selectedImage ? 'Change Image' : 'Upload Image'}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Loading State */}
        {(loading || processingWorkout) && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1500,
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}

        {/* Results Display */}
        {workoutResult && (
          <Slide direction="up" in={true}>
            {renderWorkoutResult(workoutResult)}
          </Slide>
        )}

        {/* Preview Display */}
        {showPreview && renderWorkoutPreview()}

        {/* Edit Transcript Dialog */}
        <Dialog
          fullScreen={isMobile}
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
        >
          <DialogTitle>Review Your Workout</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Review and edit your workout details below:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={editedTranscript}
              onChange={(e) => setEditedTranscript(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSubmitTranscript} variant="contained">
              Process Workout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default WorkoutLogger;
