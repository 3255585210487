import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  IconButton,
} from '@mui/material';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import { PhotoCamera, ArrowUpward } from '@mui/icons-material';

// Mock data - replace with real data from your backend
const mockData = {
  calories: {
    consumed: 1800,
    goal: 2000,
  },
  macros: [
    { name: 'Protein', value: 30 },
    { name: 'Carbs', value: 50 },
    { name: 'Fats', value: 20 },
  ],
  meals: [
    {
      id: 1,
      name: 'Breakfast',
      calories: 400,
      protein: 20,
      carbs: 45,
      fats: 15,
      feedback: 'Good protein intake! Consider adding more fiber.',
      imageUrl: 'https://via.placeholder.com/100',
    },
    // Add more meals as needed
  ],
  weeklyProgress: [
    { day: 'Mon', calories: 1900, weight: 180 },
    { day: 'Tue', calories: 1850, weight: 179.5 },
    { day: 'Wed', calories: 2000, weight: 179 },
    { day: 'Thu', calories: 1750, weight: 178.8 },
    { day: 'Fri', calories: 1800, weight: 178.5 },
  ],
  streak: 5,
};

const ClientDashboard = () => {
  const handlePhotoUpload = (event) => {
    // Implement photo upload logic
    console.log('Photo upload:', event.target.files[0]);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        {/* Daily Overview Section */}
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Daily Overview
          </Typography>
        </Grid>

        {/* Calories Progress */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Calories Progress</Typography>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={[mockData.calories]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="consumed" fill="#8884d8" name="Consumed" />
                <Bar dataKey="goal" fill="#82ca9d" name="Goal" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Macronutrient Breakdown */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Macronutrient Breakdown</Typography>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={mockData.macros}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Meals Logged */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Today's Meals</Typography>
              <Button
                variant="contained"
                startIcon={<PhotoCamera />}
                component="label"
              >
                Upload Meal
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handlePhotoUpload}
                />
              </Button>
            </Box>
            <List>
              {mockData.meals.map((meal) => (
                <ListItem
                  key={meal.id}
                  sx={{
                    border: '1px solid #eee',
                    borderRadius: 1,
                    mb: 1,
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <img
                        src={meal.imageUrl}
                        alt={meal.name}
                        style={{ width: '100%', maxWidth: 100, borderRadius: 4 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography variant="subtitle1">{meal.name}</Typography>
                      <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Chip label={`${meal.calories} cal`} size="small" />
                        <Chip label={`P: ${meal.protein}g`} size="small" />
                        <Chip label={`C: ${meal.carbs}g`} size="small" />
                        <Chip label={`F: ${meal.fats}g`} size="small" />
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {meal.feedback}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Progress Tracking */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Weekly Progress
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={mockData.weeklyProgress}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="calories"
                  stroke="#8884d8"
                  name="Calories"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="weight"
                  stroke="#82ca9d"
                  name="Weight"
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Streak Counter */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Current Streak
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ArrowUpward color="success" />
                <Typography variant="h4">{mockData.streak} Days</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientDashboard; 