import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';
import profilePic from '../images/Anonymous-Profile-pic.jpg';
import { Menu as MenuIcon } from '@mui/icons-material';
import { IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.isMobile ? '0.5rem 1rem' : '1rem 4rem'};
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.h1`
  font-size: ${props => props.isMobile ? '1.5rem' : '2rem'};
  font-weight: bold;
  color: #4A25E1;
  cursor: pointer;
  margin: 0;
`;

const Nav = styled.nav`
  display: ${props => props.isMobile ? 'none' : 'flex'};
  align-items: center;
  gap: 2rem;
`;

const MobileNav = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  font-weight: 700;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #4A25E1;
  }
`;

const DrawerNavLink = styled(NavLink)`
  padding: 1rem 2rem;
  width: 100%;
  display: block;
  border-bottom: 1px solid #eee;

  &:active {
    background: #f5f5f5;
  }
`;

const Button = styled.button`
  padding: ${props => props.isMobile ? '0.5rem 1rem' : '0.75rem 1.5rem'};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: ${props => props.isMobile ? '0.875rem' : '1rem'};
`;

const LoginButton = styled(Button)`
  background-color: #4A25E1;
  color: #FFF;
  font-weight: 700;

  &:hover {
    background-color: #3a1db1;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(74, 37, 225, 0.2);
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfilePic = styled.img`
  height: ${props => props.isMobile ? '32px' : '40px'};
  width: ${props => props.isMobile ? '32px' : '40px'};
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #4A25E1;
  cursor: pointer;
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigation = (path) => () => {
    navigate(path);
    setMobileMenuOpen(false);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/');
    setMobileMenuOpen(false);
  };

  const navLinks = [
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Workout', path: '/workout-logger' },
    { text: 'Meal', path: '/meal-logger' },
    { text: 'Recipe', path: '/meal-planner' },
    { text: 'Exercises', path: '/exercises' },
    { text: 'Profile', path: '/profile' },
  ];

  return (
    <HeaderContainer isMobile={isMobile}>
      <Logo isMobile={isMobile} onClick={handleNavigation('/')}>MiroCantCode</Logo>
      
      {/* Desktop Navigation */}
      <Nav isMobile={isMobile}>
        {navLinks.map((link) => (
          <NavLink key={link.path} onClick={handleNavigation(link.path)}>
            {link.text}
          </NavLink>
        ))}
        {user ? (
          <ProfileContainer>
            <ProfilePic src={profilePic} alt="Profile" onClick={handleNavigation('/profile')} />
            <Button onClick={handleLogout}>Logout</Button>
          </ProfileContainer>
        ) : (
          <LoginButton onClick={handleNavigation('/signin')}>Login</LoginButton>
        )}
      </Nav>

      {/* Mobile Navigation */}
      {isMobile && (
        <MobileNav>
          {user && <ProfilePic isMobile src={profilePic} alt="Profile" onClick={handleNavigation('/profile')} />}
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="menu" 
            onClick={() => setMobileMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </MobileNav>
      )}

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: '80%',
            maxWidth: '300px',
            background: '#fff',
          }
        }}
      >
        <List>
          {navLinks.map((link) => (
            <ListItem key={link.path} disablePadding>
              <DrawerNavLink onClick={handleNavigation(link.path)}>
                {link.text}
              </DrawerNavLink>
            </ListItem>
          ))}
          <ListItem disablePadding>
            {user ? (
              <DrawerNavLink onClick={handleLogout}>Logout</DrawerNavLink>
            ) : (
              <DrawerNavLink onClick={handleNavigation('/signin')}>Login</DrawerNavLink>
            )}
          </ListItem>
        </List>
      </Drawer>
    </HeaderContainer>
  );
};

export default Header;