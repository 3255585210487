import React, { useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import KitchenIcon from '@mui/icons-material/Kitchen';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

const MealPlanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Mode state
  const [mode, setMode] = useState('meal'); // 'meal' or 'ingredients'

  // Shared states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // States for "Recipe from Meal" mode
  const [mealName, setMealName] = useState('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [mealIngredients, setMealIngredients] = useState(null);
  const [mealRecipe, setMealRecipe] = useState(null);

  // States for "Recipe from Ingredients" mode
  const [availableIngredients, setAvailableIngredients] = useState('');
  const [dietType, setDietType] = useState('');
  const [strictness, setStrictness] = useState(0);
  const [suggestedRecipes, setSuggestedRecipes] = useState(null);

  const dietaryOptions = [
    'Vegetarian',
    'Vegan',
    'Gluten-free',
    'Dairy-free',
    'Keto',
    'Paleo',
    'Low-carb',
  ];

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
      // Reset states
      setError('');
      setSuccess('');
      setMealIngredients(null);
      setMealRecipe(null);
      setSuggestedRecipes(null);
    }
  };

  const handleDietaryChange = (restriction) => {
    setDietaryRestrictions(prev =>
      prev.includes(restriction)
        ? prev.filter(r => r !== restriction)
        : [...prev, restriction]
    );
  };

  // Handle meal name submission
  const handleMealSubmit = async () => {
    if (!mealName.trim()) return;
    setLoading(true);
    setError('');
    setMealIngredients(null);
    setMealRecipe(null);
    
    try {
      // First get ingredients from Claude
      const ingredientsResponse = await fetch(`${BACKEND_URL}/extract-meal-ingredients`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meal_name: mealName,
          dietary_restrictions: dietaryRestrictions
        }),
      });

      if (!ingredientsResponse.ok) {
        throw new Error('Failed to extract ingredients');
      }

      const ingredientsData = await ingredientsResponse.json();
      console.log('Claude Ingredients Response:', ingredientsData);
      setMealIngredients(ingredientsData);

      // Format ingredients into a simple comma-separated list
      const ingredientsString = ingredientsData.ingredients
        .map(ingredient => {
          if (typeof ingredient === 'object' && ingredient.name) {
            return ingredient.name;  // Just send the ingredient name, not the amount
          }
          return ingredient;
        })
        .join(', ');

      console.log('Formatted ingredients string:', ingredientsString);

      // Then get recipe using the extracted ingredients
      const recipeResponse = await fetch(`${BACKEND_URL}/generateRecipe`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ingredients: ingredientsString,
          diet: dietaryRestrictions.length > 0 ? dietaryRestrictions[0].toLowerCase() : '',
          strict: 2
        }),
      });

      if (!recipeResponse.ok) {
        const errorData = await recipeResponse.json();
        throw new Error(errorData.detail || 'Failed to generate recipe');
      }

      const recipeData = await recipeResponse.json();
      console.log('Recipe Generator Response:', recipeData);
      setMealRecipe(recipeData);
      setSuccess('Recipe generated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle ingredients submission
  const handleIngredientsSubmit = async () => {
    if (!availableIngredients.trim()) return;
    setLoading(true);
    setError('');
    setSuggestedRecipes(null);

    try {
      const response = await fetch(`${BACKEND_URL}/generateRecipe`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ingredients: availableIngredients,
          diet: dietType.toLowerCase(),
          strict: strictness
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate recipes');
      }

      const data = await response.json();
      // Format the response to match the expected structure
      const formattedData = {
        recipes: [{
          name: "Generated Recipe",
          instructions: data.recipe || data.instructions,
          additional_ingredients: data.additional_ingredients || []
        }]
      };
      setSuggestedRecipes(formattedData);
      setSuccess('Recipe generated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to format recipe text
  const formatRecipeText = (text) => {
    if (!text) return '';
    return text
      .replace(/#{1,3} /g, '') // Remove hashtags
      .replace(/\*\*/g, '') // Remove asterisks
      .replace(/###/g, '') // Remove multiple hashtags
      .replace(/\n### /g, '\n') // Remove hashtags with newlines
      .replace(/\n## /g, '\n') // Remove double hashtags
      .replace(/\n# /g, '\n') // Remove single hashtags
      .replace(/\*\*/g, '') // Remove double asterisks
      .replace(/\*/g, '') // Remove single asterisks
      .replace(/####/g, '') // Remove four hashtags
      .split('\n') // Split into lines
      .map(line => line.trim()) // Trim each line
      .filter(line => line) // Remove empty lines
      .join('\n') // Join back together
      .trim(); // Final trim
  };

  return (
    <Container maxWidth="md">
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3 }, // Responsive padding
          mt: { xs: 2, sm: 3 }, // Responsive margin
          borderRadius: 2
        }}
      >
        {/* Mode Toggle */}
        <Box sx={{ mb: { xs: 2, sm: 3 } }}>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleModeChange}
            fullWidth
            orientation={isMobile ? 'vertical' : 'horizontal'}
            sx={{
              '& .MuiToggleButton-root': {
                py: { xs: 1.5, sm: 2 },
                fontSize: { xs: '0.9rem', sm: '1rem' }
              }
            }}
          >
            <ToggleButton value="meal" aria-label="recipe from meal">
              <RestaurantIcon sx={{ mr: 1 }} />
              Recipe from Meal
            </ToggleButton>
            <ToggleButton value="ingredients" aria-label="recipe from ingredients">
              <KitchenIcon sx={{ mr: 1 }} />
              Recipe from Ingredients
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Alerts */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError('')}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
            {success}
          </Alert>
        )}

        {/* Recipe from Meal Mode */}
        {mode === 'meal' && (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: { xs: 2, sm: 3 } 
          }}>
            <Typography variant="h6" gutterBottom>
              Enter a Meal Name
            </Typography>
            <TextField
              fullWidth
              label="Meal Name"
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
              sx={{ mb: 2 }}
            />
            
            <Typography variant="subtitle1" gutterBottom>
              Dietary Restrictions
            </Typography>
            <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {dietaryOptions.map((diet) => (
                <Chip
                  key={diet}
                  label={diet}
                  onClick={() => handleDietaryChange(diet)}
                  color={dietaryRestrictions.includes(diet) ? 'primary' : 'default'}
                  variant={dietaryRestrictions.includes(diet) ? 'filled' : 'outlined'}
                />
              ))}
            </Box>

            <Button
              variant="contained"
              onClick={handleMealSubmit}
              disabled={loading || !mealName.trim()}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Generate Recipe'}
            </Button>
          </Box>
        )}

        {/* Recipe from Ingredients Mode */}
        {mode === 'ingredients' && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Enter Available Ingredients
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Ingredients"
              value={availableIngredients}
              onChange={(e) => setAvailableIngredients(e.target.value)}
              placeholder="Enter ingredients separated by commas"
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Diet Type</InputLabel>
              <Select
                value={dietType}
                onChange={(e) => setDietType(e.target.value)}
                label="Diet Type"
              >
                <MenuItem value="">None</MenuItem>
                {dietaryOptions.map((diet) => (
                  <MenuItem key={diet} value={diet.toLowerCase()}>
                    {diet}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Recipe Strictness</InputLabel>
              <Select
                value={strictness}
                onChange={(e) => setStrictness(e.target.value)}
                label="Recipe Strictness"
              >
                <MenuItem value={0}>Flexible (Can suggest additional ingredients)</MenuItem>
                <MenuItem value={1}>Moderate (Minimal additional ingredients)</MenuItem>
                <MenuItem value={2}>Strict (Use only provided ingredients)</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={handleIngredientsSubmit}
              disabled={loading || !availableIngredients.trim()}
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : 'Find Recipes'}
            </Button>
          </Box>
        )}

        {/* Results Sections */}
        {mealIngredients && (
          <Accordion 
            sx={{ 
              mt: { xs: 2, sm: 3 },
              '& .MuiAccordionSummary-content': {
                margin: { xs: '12px 0', sm: '16px 0' }
              }
            }}
          >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                backgroundColor: theme.palette.grey[50],
                borderBottom: 1,
                borderColor: 'divider'
              }}
            >
              <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                Ingredients
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: { xs: 2, sm: 3 } }}>
              <List dense={isMobile}>
                {mealIngredients.ingredients?.map((ingredient, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={
                        typeof ingredient === 'object' && ingredient.name
                          ? `${ingredient.name} - ${ingredient.amount}`
                          : ingredient
                      }
                      sx={{ 
                        '& .MuiTypography-root': {
                          fontSize: { xs: '0.9rem', sm: '1rem' }
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        )}

        {mealRecipe && (
          <Accordion 
            defaultExpanded 
            sx={{ 
              mt: 2,
              '& .MuiAccordionDetails-root': {
                px: { xs: 2, sm: 3 },
                py: { xs: 2, sm: 3 }
              }
            }}
          >
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                backgroundColor: theme.palette.grey[50],
                borderBottom: 1,
                borderColor: 'divider'
              }}
            >
              <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                Recipe Instructions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography 
                variant="body1" 
                component="div"
                sx={{
                  fontSize: { xs: '0.9rem', sm: '1rem' },
                  lineHeight: 1.8,
                  whiteSpace: 'pre-line',
                  '& section': {
                    mb: 3
                  }
                }}
              >
                {console.log('Rendering recipe:', mealRecipe)}
                {formatRecipeText(mealRecipe?.recipe || mealRecipe?.instructions || '')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}

        {suggestedRecipes && suggestedRecipes.recipes && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Suggested Recipe
            </Typography>
            {suggestedRecipes.recipes.map((recipe, index) => (
              <Accordion key={index} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{recipe.name || "Recipe Instructions"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {recipe.additional_ingredients && recipe.additional_ingredients.length > 0 && (
                    <>
                      <Typography variant="subtitle1" gutterBottom>
                        Additional Ingredients Needed:
                      </Typography>
                      <List>
                        {recipe.additional_ingredients.map((ingredient, i) => (
                          <ListItem key={i}>
                            <ListItemText primary={ingredient} />
                          </ListItem>
                        ))}
                      </List>
                      <Divider sx={{ my: 2 }} />
                    </>
                  )}
                  <Typography variant="subtitle1" gutterBottom>
                    Instructions:
                  </Typography>
                  <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                    {recipe.instructions}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default MealPlanner; 