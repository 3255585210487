import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Chip,
  Divider,
  useTheme,
  MenuItem,
} from '@mui/material';
import {
  PhotoCamera,
  Edit,
  Save,
  Restaurant,
  LocalDining,
  FreeBreakfast,
  Fastfood,
  Mic,
  MicOff,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';

const BACKEND_URL = process.env.REACT_APP_BACKEND_API_URL;

const Input = styled('input')({
  display: 'none',
});

const VoiceButton = styled(Button)(({ theme, islistening }) => ({
  height: islistening ? '120px' : '48px',
  transition: 'all 0.3s ease',
  background: islistening ? theme.palette.error.main : theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    background: islistening ? theme.palette.error.dark : theme.palette.primary.dark,
  },
}));

const MealLogger = () => {
  const theme = useTheme();

  // Add voice recording states
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [recognition, setRecognition] = useState(null);
  const [showTranscriptDialog, setShowTranscriptDialog] = useState(false);
  const [editedTranscript, setEditedTranscript] = useState('');

  // State for input methods
  const [inputText, setInputText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // State for meal data
  const [mealData, setMealData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedMealData, setEditedMealData] = useState(null);

  // Add these to the state declarations at the top
  const [mealName, setMealName] = useState('');
  const [mealType, setMealType] = useState('lunch');  // Default to lunch instead of unknown
  const [mealDate, setMealDate] = useState(new Date().toISOString().split('T')[0]);  // YYYY-MM-DD format

  // Initialize speech recognition
  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        let currentTranscript = '';
        for (let i = 0; i < event.results.length; i++) {
          currentTranscript += event.results[i][0].transcript;
        }
        setTranscript(currentTranscript);
        setEditedTranscript(currentTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setError('Speech recognition error: ' + event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
        if (transcript) {
          setShowTranscriptDialog(true);
        }
      };

      setRecognition(recognition);
    } else {
      setError('Speech recognition is not supported in this browser');
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, []);

  // Voice recording handlers
  const startRecording = async () => {
    setError('');
    setTranscript('');
    setEditedTranscript('');
    
    if (recognition) {
      try {
        await recognition.start();
        setIsListening(true);
      } catch (err) {
        console.error('Error starting recognition:', err);
        setError('Could not start speech recognition');
      }
    }
  };

  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
      setShowTranscriptDialog(true);
    }
  };

  const handleSubmitTranscript = async () => {
    try {
      setLoading(true);
      setShowTranscriptDialog(false);

      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${BACKEND_URL}/process-voice-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          transcript: editedTranscript,
          meal_type: 'unknown',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to process voice input');
      }

      const data = await response.json();
      console.log('Received meal data:', data);
      
      // Set the data directly since it's already in the correct format
      setMealData(data);
      setSuccess('Voice input processed successfully!');
    } catch (error) {
      setError('Failed to process voice input: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle text input submission
  const handleTextSubmit = async () => {
    if (!inputText.trim()) return;
    
    setLoading(true);
    setError('');
    
    try {
      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${BACKEND_URL}/process-text-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          text: inputText,
          meal_type: 'unknown',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to process meal text');
      }

      const data = await response.json();
      console.log('Received meal data:', data);
      setMealData(data.result);
      setInputText('');
      setSuccess('Meal processed successfully!');
    } catch (err) {
      setError('Failed to process meal text: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Add image compression function
  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 0.1,          // Reduce to 100KB max
      maxWidthOrHeight: 800,    // Smaller max dimensions
      useWebWorker: true,
      fileType: 'image/jpeg',   // Force JPEG
      initialQuality: 0.7       // Lower initial quality
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log('Original file size:', imageFile.size / 1024 / 1024, 'MB');
      console.log('Compressed file size:', compressedFile.size / 1024 / 1024, 'MB');
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      throw new Error('Failed to compress image');
    }
  };

  // Update handleImageUpload to use data.result
  const handleImageUpload = async (event) => {
    try {
      setLoading(true);
      setError(null);
      const file = event.target.files[0];
      if (!file) {
        setError('No file selected');
        return;
      }

      // Get auth token from localStorage
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      // Show preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Compress image before upload
      const compressedFile = await compressImage(file);
      const formData = new FormData();
      formData.append('file', compressedFile, 'food_image.jpg');

      console.log('Sending request to:', `${BACKEND_URL}/analyzeFoodPlate`);
      const response = await fetch(`${BACKEND_URL}/analyzeFoodPlate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server responded with ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received meal data:', data);
      
      if (!data || (!data.foods && !data.rapidapi_analysis?.foods_identified)) {
        throw new Error('No food items were identified in the image');
      }
      
      // Use the combined response format
      setMealData({
        foods: data.foods || data.rapidapi_analysis?.foods_identified || [],
        total_nutrition: data.total_nutrition || data.rapidapi_analysis?.total_nutrition || {},
        meal_analysis: data.meal_analysis || data.rapidapi_analysis?.meal_analysis || {},
        health_insights: data.health_insights || data.rapidapi_analysis?.health_insights || {}
      });

      setSuccess('Image analyzed successfully!');
    } catch (err) {
      console.error('Error uploading image:', err);
      setError(`Failed to analyze food image: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFoodEdit = (index, field, value) => {
    if (!editMode) return;

    const updatedMealData = { ...mealData };
    const food = updatedMealData.foods[index];

    // Update the specific field
    if (field === 'name' || field === 'portion') {
      food[field] = value;
    } else {
      // Handle nutrition fields
      if (!food.nutrition) food.nutrition = {};
      food.nutrition[field] = Number(value);
    }

    // Recalculate total nutrition
    const totals = updatedMealData.foods.reduce((acc, food) => {
      acc.calories += Number(food.nutrition?.calories || 0);
      acc.protein += Number(food.nutrition?.protein || 0);
      acc.carbs += Number(food.nutrition?.carbs || 0);
      acc.fats += Number(food.nutrition?.fats || 0);
      return acc;
    }, { calories: 0, protein: 0, carbs: 0, fats: 0 });

    updatedMealData.total_nutrition = totals;
    setMealData(updatedMealData);
  };

  // Handle saving meal
  const handleSaveMeal = async () => {
    try {
      setLoading(true);
      setError('');

      if (!mealData) {
        setError('No meal data to save');
        return;
      }

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Not authenticated');
      }

      const mealCreateData = {
        name: mealName || `Meal on ${new Date().toLocaleString()}`,
        meal_type: mealType,
        date: new Date(mealDate).toISOString(),
        total_nutrition: mealData.total_nutrition,
        foods: mealData.foods,
        meal_analysis: mealData.meal_analysis,
        health_insights: mealData.health_insights
      };

      const response = await fetch(`${BACKEND_URL}/save-meal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(mealCreateData)
      });

      if (!response.ok) {
        throw new Error('Failed to save meal');
      }

      setSuccess('Meal saved successfully!');
      // Reset form
      setMealData(null);
      setSelectedImage(null);
      setInputText('');
      setMealName('');
      setMealType('lunch');
      setMealDate(new Date().toISOString().split('T')[0]);
    } catch (error) {
      setError('Failed to save meal: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Render nutrition table
  const renderNutritionTable = (nutrition) => (
    <TableContainer component={Paper} variant="outlined" sx={{ mt: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nutrient</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(nutrition).map(([nutrient, value]) => (
            <TableRow key={nutrient}>
              <TableCell component="th" scope="row" sx={{ textTransform: 'capitalize' }}>
                {nutrient.replace('_', ' ')}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Render meal analysis
  const renderMealAnalysis = (analysis) => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Meal Analysis
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Meal Type
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {analysis.type}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Balance Score
            </Typography>
            <Typography variant="body1">
              {analysis.balance_score}/10
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Macronutrient Ratios
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Chip 
            label={`Protein: ${analysis.protein_ratio}%`}
            color="primary"
            variant="outlined"
          />
          <Chip 
            label={`Carbs: ${analysis.carb_ratio}%`}
            color="secondary"
            variant="outlined"
          />
          <Chip 
            label={`Fat: ${analysis.fat_ratio}%`}
            color="warning"
            variant="outlined"
          />
        </Box>
      </Box>
    </Box>
  );

  // Render health insights
  const renderHealthInsights = (insights) => {
    if (!insights) return null;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Health Insights
        </Typography>
        
        {insights.positive_aspects && insights.positive_aspects.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="success.main">
              Positive Aspects:
            </Typography>
            {insights.positive_aspects.map((aspect, index) => (
              <Typography key={index} variant="body2">
                • {aspect}
              </Typography>
            ))}
          </Box>
        )}

        {insights.areas_for_improvement && insights.areas_for_improvement.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="warning.main">
              Areas for Improvement:
            </Typography>
            {insights.areas_for_improvement.map((area, index) => (
              <Typography key={index} variant="body2">
                • {area}
              </Typography>
            ))}
          </Box>
        )}

        {insights.suggestions && insights.suggestions.length > 0 && (
          <Box>
            <Typography variant="subtitle2" color="info.main">
              Suggestions:
            </Typography>
            {insights.suggestions.map((suggestion, index) => (
              <Typography key={index} variant="body2">
                • {suggestion}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mb: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }} onClose={() => setSuccess('')}>
            {success}
          </Alert>
        )}

        {/* Input Methods */}
        <Grid container spacing={2}>
          {/* Voice Input */}
          <Grid item xs={12}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 2,
                background: isListening ? theme.palette.error.main : theme.palette.background.paper,
                transition: 'all 0.3s ease'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" color={isListening ? 'white' : 'text.primary'}>
                  Voice Input
                </Typography>
                <VoiceButton
                  variant="contained"
                  onClick={isListening ? stopRecording : startRecording}
                  islistening={isListening ? 1 : 0}
                  startIcon={isListening ? <MicOff /> : <Mic />}
                >
                  {isListening ? 'Stop Recording' : 'Start Recording'}
                </VoiceButton>
              </Box>
              {isListening && (
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mt: 2, 
                    color: 'white',
                    minHeight: '50px' 
                  }}
                >
                  {transcript || 'Listening...'}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Manual Input */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Manual Input
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Describe your meal (e.g., '2 eggs, toast with butter, orange juice')"
                sx={{ mb: 2 }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleTextSubmit}
                disabled={!inputText.trim() || loading}
              >
                Process Meal
              </Button>
            </Paper>
          </Grid>

          {/* Image Upload */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Upload Food Image
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {selectedImage && (
                  <Box
                    component="img"
                    src={selectedImage}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: 200,
                      mb: 2,
                      borderRadius: 1,
                    }}
                  />
                )}
                <label htmlFor="meal-image-upload">
                  <Input
                    accept="image/*"
                    id="meal-image-upload"
                    type="file"
                    onChange={handleImageUpload}
                    disabled={loading}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    disabled={loading}
                  >
                    Upload Photo
                  </Button>
                </label>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Edit Transcript Dialog */}
        <Dialog
          open={showTranscriptDialog}
          onClose={() => setShowTranscriptDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Review Your Meal Description</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={editedTranscript}
              onChange={(e) => setEditedTranscript(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTranscriptDialog(false)}>Cancel</Button>
            <Button onClick={handleSubmitTranscript} variant="contained">
              Process Meal
            </Button>
          </DialogActions>
        </Dialog>

        {/* Loading State */}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Meal Data Display */}
        {mealData && (
          <Card sx={{ mt: 3 }}>
            <CardContent>
              {/* Meal Details Header */}
              <Box sx={{ mb: 3 }}>
                <Typography variant="h5" sx={{ 
                  mb: 3,
                  fontSize: { xs: '1.5rem', sm: '1.75rem' },
                  fontWeight: 600
                }}>
                  Meal Details
                </Typography>
                
                <Grid container spacing={3}>
                  {/* Meal Name - Full width on mobile */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Meal Name"
                      value={mealName}
                      onChange={(e) => setMealName(e.target.value)}
                      variant="outlined"
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '1.1rem', sm: '1rem' }
                        },
                        '& .MuiInputBase-input': {
                          fontSize: { xs: '1.1rem', sm: '1rem' },
                          padding: { xs: '16px 14px', sm: '14px 12px' }
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: { xs: '2px', sm: '1px' }
                          }
                        }
                      }}
                    />
                  </Grid>

                  {/* Meal Type - Full width on mobile */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      select
                      label="Meal Type"
                      value={mealType}
                      onChange={(e) => setMealType(e.target.value)}
                      variant="outlined"
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '1.1rem', sm: '1rem' }
                        },
                        '& .MuiInputBase-input': {
                          fontSize: { xs: '1.1rem', sm: '1rem' },
                          padding: { xs: '16px 14px', sm: '14px 12px' }
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: { xs: '2px', sm: '1px' }
                          }
                        }
                      }}
                    >
                      <MenuItem value="breakfast">Breakfast</MenuItem>
                      <MenuItem value="lunch">Lunch</MenuItem>
                      <MenuItem value="dinner">Dinner</MenuItem>
                      <MenuItem value="snack">Snack</MenuItem>
                    </TextField>
                  </Grid>

                  {/* Date - Full width on mobile */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Date"
                      value={mealDate}
                      onChange={(e) => setMealDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontSize: { xs: '1.1rem', sm: '1rem' }
                        },
                        '& .MuiInputBase-input': {
                          fontSize: { xs: '1.1rem', sm: '1rem' },
                          padding: { xs: '16px 14px', sm: '14px 12px' }
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: { xs: '2px', sm: '1px' }
                          }
                        }
                      }}
                    />
                  </Grid>

                  {/* Save Button - Full width on mobile */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSaveMeal}
                      disabled={loading}
                      startIcon={<Save />}
                      sx={{
                        mt: { xs: 1, sm: 2 },
                        height: { xs: '56px', sm: '48px' },
                        fontSize: { xs: '1.1rem', sm: '1rem' },
                        fontWeight: 600
                      }}
                    >
                      Save Meal
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ my: 3 }} />

              {/* After the meal type/date inputs, before the Foods List */}
              <Box sx={{ mt: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Nutritional Information
                  <IconButton 
                    size="small" 
                    onClick={() => {
                      if (!editMode && mealData) {
                        // Initialize editedMealData when entering edit mode
                        setEditedMealData({
                          ...mealData,
                          total_nutrition: {
                            ...mealData.total_nutrition
                          }
                        });
                      }
                      setEditMode(!editMode);
                    }}
                    sx={{ ml: 1 }}
                  >
                    {editMode ? <Save /> : <Edit />}
                  </IconButton>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Calories"
                      type="number"
                      value={editMode && editedMealData?.total_nutrition ? editedMealData.total_nutrition.calories || 0 : mealData?.total_nutrition?.calories || 0}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedMealData({
                            ...editedMealData,
                            total_nutrition: {
                              ...(editedMealData?.total_nutrition || {}),
                              calories: Number(e.target.value)
                            }
                          });
                        }
                      }}
                      disabled={!editMode}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Protein (g)"
                      type="number"
                      value={editMode && editedMealData?.total_nutrition ? editedMealData.total_nutrition.protein || 0 : mealData?.total_nutrition?.protein || 0}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedMealData({
                            ...editedMealData,
                            total_nutrition: {
                              ...(editedMealData?.total_nutrition || {}),
                              protein: Number(e.target.value)
                            }
                          });
                        }
                      }}
                      disabled={!editMode}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Carbs (g)"
                      type="number"
                      value={editMode && editedMealData?.total_nutrition ? editedMealData.total_nutrition.carbs || 0 : mealData?.total_nutrition?.carbs || 0}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedMealData({
                            ...editedMealData,
                            total_nutrition: {
                              ...(editedMealData?.total_nutrition || {}),
                              carbs: Number(e.target.value)
                            }
                          });
                        }
                      }}
                      disabled={!editMode}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Fat (g)"
                      type="number"
                      value={editMode && editedMealData?.total_nutrition ? editedMealData.total_nutrition.fats || 0 : mealData?.total_nutrition?.fats || 0}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedMealData({
                            ...editedMealData,
                            total_nutrition: {
                              ...(editedMealData?.total_nutrition || {}),
                              fats: Number(e.target.value)
                            }
                          });
                        }
                      }}
                      disabled={!editMode}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Foods List */}
              {mealData?.foods && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Foods
                  </Typography>
                  {mealData.foods.map((food, index) => (
                    <Paper key={index} sx={{ p: 1, mb: 1 }}>
                      {editMode ? (
                        <Box sx={{ mb: 1 }}>
                          <TextField
                            fullWidth
                            label="Food Name"
                            value={food.name}
                            onChange={(e) => handleFoodEdit(index, 'name', e.target.value)}
                            sx={{ mb: 1 }}
                          />
                          <TextField
                            fullWidth
                            label="Portion"
                            value={food.portion}
                            onChange={(e) => handleFoodEdit(index, 'portion', e.target.value)}
                            sx={{ mb: 1 }}
                          />
                          <Grid container spacing={2}>
                            {Object.entries(food.nutrition).map(([key, value]) => (
                              <Grid item xs={6} key={key}>
                                <TextField
                                  fullWidth
                                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                                  value={value}
                                  onChange={(e) => handleFoodEdit(index, key, e.target.value)}
                                  type="number"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="subtitle1">
                            {food.name} - {food.portion}
                          </Typography>
                          {renderNutritionTable(food.nutrition)}
                        </>
                      )}
                    </Paper>
                  ))}
                </Box>
              )}

              {/* Total Nutrition */}
              {mealData?.total_nutrition && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Total Nutrition
                  </Typography>
                  {editMode ? (
                    <Grid container spacing={2}>
                      {Object.entries(editedMealData.total_nutrition).map(([key, value]) => (
                        <Grid item xs={6} sm={4} key={key}>
                          <TextField
                            fullWidth
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            value={value}
                            onChange={(e) => {
                              setEditedMealData({
                                ...editedMealData,
                                total_nutrition: {
                                  ...editedMealData.total_nutrition,
                                  [key]: parseFloat(e.target.value) || 0
                                }
                              });
                            }}
                            type="number"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    renderNutritionTable(mealData.total_nutrition)
                  )}
                </Box>
              )}

              {/* Meal Analysis */}
              {mealData?.meal_analysis && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Meal Analysis
                  </Typography>
                  {editMode ? (
                    <Grid container spacing={2}>
                      {Object.entries(editedMealData.meal_analysis).map(([key, value]) => (
                        <Grid item xs={12} sm={6} key={key}>
                          <TextField
                            fullWidth
                            label={key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1)}
                            value={value}
                            onChange={(e) => {
                              setEditedMealData({
                                ...editedMealData,
                                meal_analysis: {
                                  ...editedMealData.meal_analysis,
                                  [key]: e.target.value
                                }
                              });
                            }}
                            type={typeof value === 'number' ? 'number' : 'text'}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    renderMealAnalysis(mealData.meal_analysis)
                  )}
                </Box>
              )}

              {/* Health Insights */}
              {mealData?.health_insights && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Health Insights
                  </Typography>
                  {editMode ? (
                    <Box>
                      <Typography variant="subtitle2" gutterBottom>Positive Aspects</Typography>
                      {editedMealData.health_insights.positive_aspects.map((aspect, index) => (
                        <TextField
                          key={index}
                          fullWidth
                          value={aspect}
                          onChange={(e) => {
                            const updatedAspects = [...editedMealData.health_insights.positive_aspects];
                            updatedAspects[index] = e.target.value;
                            setEditedMealData({
                              ...editedMealData,
                              health_insights: {
                                ...editedMealData.health_insights,
                                positive_aspects: updatedAspects
                              }
                            });
                          }}
                          sx={{ mb: 1 }}
                        />
                      ))}

                      <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>Areas for Improvement</Typography>
                      {editedMealData.health_insights.areas_for_improvement.map((area, index) => (
                        <TextField
                          key={index}
                          fullWidth
                          value={area}
                          onChange={(e) => {
                            const updatedAreas = [...editedMealData.health_insights.areas_for_improvement];
                            updatedAreas[index] = e.target.value;
                            setEditedMealData({
                              ...editedMealData,
                              health_insights: {
                                ...editedMealData.health_insights,
                                areas_for_improvement: updatedAreas
                              }
                            });
                          }}
                          sx={{ mb: 1 }}
                        />
                      ))}

                      <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>Suggestions</Typography>
                      {editedMealData.health_insights.suggestions.map((suggestion, index) => (
                        <TextField
                          key={index}
                          fullWidth
                          value={suggestion}
                          onChange={(e) => {
                            const updatedSuggestions = [...editedMealData.health_insights.suggestions];
                            updatedSuggestions[index] = e.target.value;
                            setEditedMealData({
                              ...editedMealData,
                              health_insights: {
                                ...editedMealData.health_insights,
                                suggestions: updatedSuggestions
                              }
                            });
                          }}
                          sx={{ mb: 1 }}
                        />
                      ))}
                    </Box>
                  ) : (
                    renderHealthInsights(mealData.health_insights)
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  );
};

export default MealLogger; 