import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Layout from './components/Layout';
import Register from "./components/Register";
import SignIn from "./components/Signin";
import VerifyLogin from "./components/VerifyLogin";
import VerifyRegister from "./components/VerifyRegister";
import { UserProvider } from "./components/UserContext";
import Dashboard from "./components/Dashboard";
import ExerciseDB from './components/ExerciseDB';
import DietPlanner from './components/DietPlanner';
import WorkoutPlanner from './components/WorkoutPlanner';
import NutritionCalculator from './components/NutritionCalculator';
import NutritionAdvice from './components/NutritionAdvice';
import Profile from './components/Profile';
import LogMeal from './components/LogMeal';
import LogWorkoutCardio from './components/LogWorkoutCardio';
import LogWorkoutGym from './components/LogWorkoutGym';
import UserProfile from './components/UserProfile';
import CreateMeal from './components/CreateMeal';
import CreateWorkout from './components/CreateWorkout';
import UserCalendar from './components/UserCalendar';
import Jamiro from './components/Jamiro';
import Goals from './components/Goals';
import Workouts from './components/Workouts'; // Import the new WorkoutList component
import AnalyzeFood from './components/AnalyzeFood';
import RecipeGenerator from './components/RecipeGenerator';
import GenerateAWorkoutPlan from './components/GenerateAWorkoutPlan';
import MealPlan from './components/MealPlan';
import ClientDashboard from './components/ClientDashboard';
import AdminDashboard from './components/AdminDashboard';
import WorkoutLogger from './components/WorkoutLogger';
import MealLogger from './components/MealLogger';
import MealPlanner from './components/MealPlanner';

function App() {
  const [filters, setFilters] = useState({});

  const handleSearch = (filterData) => {
    setFilters(filterData);
  };

  return (
    <Router>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-login/:token" element={<VerifyLogin />} />
            <Route path="/verify-register/:token" element={<VerifyRegister />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/goals" element={<Goals />} />
            <Route path="/workouts" element={<Workouts />} />
            <Route path="/calendar" element={<UserCalendar />} />
            <Route path="/jamiroperpageurl" element={<Jamiro />} />
            <Route path="/exercises" element={<ExerciseDB />} />
            <Route path="/meal-planner" element={<MealPlanner />} />
            <Route path="/meal-logger" element={<MealLogger />} />
            <Route path="/workout-logger" element={<WorkoutLogger />} />
            <Route path="/client-dashboard" element={<ClientDashboard />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </Layout>
      </UserProvider>
    </Router>
  );
}

export default App;